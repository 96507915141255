// import xlsx from "xlsx";
import service from "@/common/request.js";
import qs from "qs";

const inst = () => service;
/**
 * 用户查询记录
 * @param {Object} params
 * {
 *   pagesize: 10,
 *   pagenumber: 1,
 *   type: all\public\private
 * }
 */
export function userActivityInfos({
  pagesize = 10,
  pagenumber = 1,
  type = "all",
}) {
  return inst().get(`/uc/actions/${pagesize}/${pagenumber}/${type}/`);
}

/**
 * 记录用户分析操作
 * @param {Object} params {
 *  "company_code": "企业代码",
    "menu_id": "菜单编号(2:财报健康度,
                4:行业特色数据
                3:标准财务分析
                1:简版财务报表
                5:对标分析)",
    "parsing_time": "分析年份",
    "industry_type": "行业名称",
    "industry_number": "行业代码",
    "industry_range": "行业范围('0':'全行业', '1':'大型行业', '2':'中型行业', '3':'小型行业')"
 * }
 */
export function logActivity(params) {
  return inst().post("/uc/actions/", params);
}

/**
 * 用户查询记录详情
 * @param {string} companyCode 企业编码
 */
export function userActivityExtra(companyCode) {
  return inst().post("/users/activityinfoextra/", {
    company_code: companyCode,
  });
}

/**
 * 读取excel表格
 * @param {String} url excel文件URL
 */
// export function readExcel(url) {
//   return axios
//     .get(url, {
//       responseType: "arraybuffer",
//     })
//     .then((res) => {
//       var data = new Uint8Array(res.data);
//       var workbook = xlsx.read(data, { type: "array" });
//       return workbook;
//     });
// }

/**
 * 查询行业列表
 * @param {Object} params
 * {
 * // 行业大类（申万sw，统计局：tj）
 *  Industry_type: 'sw',
    Parent_code: 'sw01'
 * }
 */
export function queryIndus(params) {
  return inst().post("/company/industry/children/", {
    Industry_type: params.Industry_type,
    Parent_code: params.Parent_code || "",
  });
}

/**
 * 创建公司
 * @param {Object} params
 * @example
 * {
 *  company_name: '',
 *  industry_code: ''
 * }
 */
export function newCompany(params = {}) {
  return inst().post("/company/create/", {
    company_name: params.companyName,
    industry_code: params.indusCode,
    company_type: params.company_type,
  });
}

/**
 * 编辑公司名称和行业
 * @param {Object} params
 * {
    company_code: '',
    name: '',
    statistics_code: ''
 * }
 */
export function editCompany(params = {}) {
  return inst().post("/company/update/", {
    company_code: params.company_code,
    name: params.company_name,
    statistics_code: params.industry_code,
    company_type: params.company_type,
    CSRC_code: params.CSRC_code,
  });
}

export function myNewCompanys({ pagesize, pagenumber }, params) {
  let param = qs.parse(params)
  if (param.company_type == "3") {
    params = `page_size=${pagesize}&page=${pagenumber}&start_time=${param.start_time || ''}&end_time=${param.end_time || ''}&company_name=${param.company_name || ''}&company_type=${3}&company_type=${4}&company_type=${5}`
  } else {
    params = `page_size=${pagesize}&page=${pagenumber}&start_time=${param.start_time || ''}&end_time=${param.end_time || ''}&company_name=${param.company_name || ''}&company_type=${''}`
  }
  return inst().get(
    `company/my/search-paging-plus/?${params}`
  );
}

/**
 * 上报财报
 * @param {Object} params
 */
export function postFinancialReport(params = {}) {
  return inst().post("/upload/templated-fs/save/", params);
}

/**
 * 调用支付宝
 * @param {Object} params
 * {
    item_name: '',
    item_discribe: '',
    price: 20
    }
 */
export function alipay(params = {}) {
  const axiosInst = inst();
  return axiosInst.post("/pay/alipay/", {
    item_name: params.item_name || "",
    item_discribe: params.item_discribe || "",
    price: params.price,
    order_num: params.order_num,
    return_url: params.return_url,
  });
}

/**
 * 调用微信
 * @param {Object} params
 * {
    item_name: '',
    item_discribe: '',
    price: 20
    }
 */
export function wxpay(params = {}) {
  const axiosInst = inst();
  return axiosInst.post("/pay/wxpay/", {
    item_name: params.item_name || "",
    item_discribe: params.item_discribe || "",
    price: params.price,
    return_url: params.return_url,
    order_num: params.order_num,
  });
}
export function userBasicInfo() {
  return inst().get("/uc/userinfo/");
}

/**
 * 查看点数是否充足
 * @param {Object} params 请求参数
 * @example
 * {
    company_code: 企业代码,
    menu_id: 菜单编号1:简版财务报表 2:财报健康度 3:标准财务分析 4:行业特色数据 5：对标分析,
    parse_time: 分析年份,
    industry_type: 行业名称,
    industry_number: 行业代码,
    industry_range: 行业范围 '0':全行业 '1':大型行业 '2':中型行业 '3':小型行业
   }
 * @returns
   {
     Status: '1',
     Message: '点数充足。'
   }
 */
export function checkpoint(params) {
  return inst().post("/uc/point/check/", params);
}

/**
 * 可查年份
 * @param {Object} params 查询参数
 * @example
 * {
 *  company_code: 企业代码,
 *  menu_name: 菜单名 simple, judge, standard
 * }
 * @returns
 * {
 *  Status: '1',
 *  Message: '...',
 *  Result: [ 2018, 2019, ...]
 * }
 */
// export function yearList(params) {
//   return inst().post("company/fs/analyzable-years/", {
//     company_code: params.company_code,
//     menu_name: params.menu_name || "simple",
//   });
// }
export function yearList(params) {
  return inst().post("company/fs/analyzable-years/", params);
}

export function tjINdustryList(params) {
  return inst().post(`${window.BASE_URL}/company/industry/detail/`, params);
}

export function industryList(params) {
  return inst().post("/company/industry/children/", {
    Industry_type: params.Industry_type || "tj",
    Parent_code: params.Parent_code || "",
  });
}

/**
 *
 * @param {int} page 页码
 * @returns
 * @example
 * {
 *  count: 86, // 总数
 *  next: 'http://127.0.0.1:8000/uc/orders/?page=2',
 *  previous: null,
 *  results: [
 *    {
 *      "create_time": "2018-09-30 17:14:05.187397",
        "status": "f",
        "item_name": "aaa",
        "price_to_pay": "0.0",
        "item_code": "PO-15382988453",
        "user_code_id": "db292162-89a4-11e8-bd7a-3c95098a9434"
 *    }
 * ]
 * }
 */

export function getOrders(params) {
  return inst().get(`/uc/user/recharge/?page=${params.page}&page_size=${params.page_size}&user_code_id=${params.user_code_id}`);
}


export function orders(page, pay = 0, status = 0, time = []) {
  let timeStr = "";
  if (time && time[0]) {
    const start_time = time[0] + " 00:00:00";
    const end_time = time[1] + " 23:59:29";
    timeStr += `&start_time=${start_time}&end_time=${end_time}`;
  }
  return inst().get(
    `/uc/orders/?limit=10&offset=${(page - 1) * 10 ||
    0}&pay=${pay}&status=${status}${timeStr}`
  );
}

export function simpleAnalysis(params) {
  return inst().post(`/financing-calc/simple/`, params);
}

/**
 * 获取系统规则
 * @param {string} index_name 规则名称
 */
export const FindSystemRule = (param) =>
  inst().post(`v3/FindSystemRule/`, param);

/**
 * 获取自定义规则
 * @param {string} index_name 规则名称
 */
export const FindCustomRule = (param) =>
  inst().post(`v3/FindCustomRule/`, param);

/**
 * 规则名称
 *  @param {string} index_name 规则名称
 *  @param {string} report_type 报表口径
 */
export const FindSubject = (param) => inst().post(`v3/FindSubject/`, param);

/**
 * 自定义规则保存和编辑
 *  @param {string} rule_pass 通过描述
 *  @param {string} rule_notpass 未通过描述
 *  @param {string} rule_formula 公式
 *  @param {string} rule_name 名称
 *  @param {string} rule_version 版本号
 *  @param {string} rule_class 类型
 *  @param {Number} industry_code 什么表 默认0
 *  @param {Number} rule_id 规则id 有这个参数 是编辑 没有这个参数是保存
 */
export const CustomRule = (param) => inst().post(`v3/CustomRule/`, param);

/**
 *  自定义规则启用或停用
 *  @param {string} rule_state 1启用 2 停用
 *  @param {Number} rule_id 规则id
 */
export const SetCustomRuleStatus = (param) =>
  inst().post(`v3/SetCustomRuleStatus/`, param);

/**
 *  自定义规则和雷达图的计算
 *  @param {object} report 此接口/risk/formated-fs/拿到的数据
 *  @param {int} year 下拉框年份
 */
export const CalculateCustomRule = (param) =>
  inst().post(`v3/CalculateCustomRule/`, param);

/**
 *  获取用户权限 根据用户权限  1 普通用户 2 vip 用户 3 后台
 */

export const CheckUserPower = (param) =>
  inst().get(`/uc/role/check/`, param);

/**
 *  在对标分析搜索页面输入公司名称或股票代码进行搜索
 *  @param {string} name 搜索参数
 *
 */

export const stockCodeSearch = (param) =>
  inst().post(`/company/listed/search-by-fs-info/`, param);

/**
 *  在对标分析搜索页面输入公司名称或股票代码进行搜索
 *  @param {string} name 搜索参数
 */

export const CustomCompanyNameSearch = (param) =>
  inst().post(`/company/my/search-by-fs-info/`, param);

/**
 *  获取开始对比数据
 *  @param {string} company_code 公司编码
 *  @param {int} year 下拉框年份
 *  @param {string} report_type 下拉框年份
 *  @param {string} report_time 下拉框年份
 *  @param {string} data_list 所选的参数
 *
 */
export const calculation_data = (param) =>
  inst().post(`risk/benchmarking/`, param);

/**
 *  对比之后的排序
 *  @param {string} rule_name 规则名称
 *  @param {list} data 对标数据
 *
 */
export const order_data = (param) => inst().post(`risk/benchmarking/sorted/`, param);

/**
 *  在每次对标分析后扣除点数
 * point_num
 *  @param {str or int} point_num 所选公司的数量
 */
export const benchmarking_point = (param) =>
  inst().post(`v3/benchmarking_point/`, param);

/**
 *  在对标分析界面,上市公司企业太多,下拉框年份最近年份到2010年,自定企业年份下拉框年份则通过此接口获取年份列表
 */
export const custom_years = (param) => inst().post(`company/fs/years/`, param);

/**
 *  财务粉饰度
 * http://127.0.0.1:8000/whitewash/summary/
 *
 */

export const FinWhitewashCal = (param) =>
  inst(true).post(`whitewash/summary/`, param);
export const AiAudit = (param) => inst().post(`3v3/AiAudit/`, param);
export const FinWhitewashAnalyze = (param) =>
  inst().post(`3v3/FinWhitewashAnalyze/`, param);



/**
 *  财务健康分析
 * http://172.16.11.133:8000/risk/BankruptcyIndex/
 *
 */
export const BankruptcyIndex = (param) =>
  inst().post(`risk/BankruptcyIndex/`, { ...param });

/**
 *  财务健康分析
 * http://172.16.11.133:8000/risk/five-abilities/
 *
 */
export const FiveAbilityNum = (param) =>
  inst(false).post(`risk/five-abilities/`, param);
export const FormatSCReport = (param) =>
  inst().post(`3v3/FormatSCReport/`, param);
export const FormatCNReport = (param) =>
  inst().post(`3v3/FormatCNReport/`, param);
export const GrowthRate = (param) => inst().post(`3v3/GrowthRate/`, param);

/**
 *
 * http://172.16.11.133:8000/risk/five-abilities/
 *
 */
export const FormatSCInput = (param) =>
  inst(false).post(`risk/formated-fs/`, param);

/**
 *
 * http://172.16.11.133:8000/3risk/four-abilities/
 *
 */
export const stdfinanalysis = (param) =>
  inst(false).post(`risk/four-abilities/`, param);

/**
 * 小微企业 四大能力雷达图
 * http://172.16.11.133:8000/3v3/MicroFiveAbilityNum/
 *
 */

export const MicroFiveAbilityNum = (param) =>
  inst().post(`3v3/MicroFiveAbilityNum/`, param);

/**
 * 小微企业 财务粉饰
 * http://172.16.11.133:8000/3v3/MicroFinWhitewashCal/
 *
 */

export const MicroFinWhitewashCal = (param) =>
  inst().post(`3v3/MicroFinWhitewashCal/`, param);
/**
 * 小微企业 专家检验结果
 * http://172.16.11.133:8000/3v3/MicroWhitewashAnalysis/
 *
 */

export const MicroWhitewashAnalysis = (param) =>
  inst().post(`3v3/MicroWhitewashAnalysis/`, param);

/**
 * 小微企业 四大能力
 * http://172.16.11.133:8000/3v3/MicroGrowthRate/ !
 *
 */

export const MicroStdFinAnalysis = (param) =>
  inst().post(`3v3/MicroStdFinAnalysis/`, param);

/**
 * 小微企业 四大能力各项指标值增长率：/3v3/MicroStdFinAnalysis/
 *
 *
 */

export const MicroGrowthRate = (param) =>
  inst().post(`3v3/MicroGrowthRate/`, param);

/**
 * 事业企业 四大能力雷达图
 * http://172.16.11.133:8000/3v3/InstitutionWealthHealth/
 *
 */

export const InstitutionWealthHealth = (param) =>
  inst().post(`/3v3/InstitutionWealthHealth/`, param);

/**
 * 事业单位 专家检验结果
 * http://172.16.11.133:8000/3v3/InstitutionWhitewashAnalysis/
 *
 */

export const InstitutionWhitewashAnalysis = (param) =>
  inst().post(`3v3/InstitutionWhitewashAnalysis/`, param);

/**
 * 事业单位 各项指标
 * http://172.16.11.133:8000/3v3/InstitutionStdFinAnalysis/
 *
 */

export const InstitutionStdFinAnalysis = (param) =>
  inst().post(`3v3/InstitutionStdFinAnalysis/`, param);

/**
 * 上市 各项指标
 * http://172.16.11.133:8000/3v3/InstitutionStdFinAnalysis/
 *
 */

export const ExpertRulesCalculation = (param) =>
  inst().post(`3v3/ExpertRulesCalculation/`, param);

/**
 *  获取分类
 * http://172.16.11.133:8000/company/industry/tj/
 *
 */

export const GlIndustryLinkage = (params) =>
  inst().get(`company/industry/tj/`, { params: params });
export const SwIndustryLinkage = (param) =>
  inst().get(`company/industry/sw/`);
export const GbIndustryLinkage = (param) =>
  inst().get(`company/industry/gb/`);
/**
 *  财务风险总评价
 * http://172.16.11.133:8000/3v3/IndustryGradingCalculation/
 *
 */

export const IndustryGradingCalculation = (param) =>
  inst().post(`3v3/IndustryGradingCalculation/`, param);

/**
 *  五大能力
 * http://172.16.11.133:8000causeIndexCalc
 * {"stock_code":"000002","report_year":2018,"report_time":"00", "company_name": "", "report_type": "00"}
 */

export const stdval = (param) => inst().post(`/risk/index-calc/`, param);

// 行业排名
export const toTop = (param) => inst().post(`/risk/industry-position/`, param);


/**
 *  五大能力分类默认值
 * http://192.168.43.150:8000/3v3/cate
 *
 */

export const cate = (param) => inst().get(`/risk/index-cate/`, param);

/**
 *  上传文件生成json
 * http://172.16.32.144:8000/
 *
 */

export const uploadreports = (param, config) =>
  inst().post(`/upload/templated-fs/recognize/`, param, config);

// 华润银行的校验
export const uploadr_eports = (param, config) =>
  inst().post(`/v3/crbank/upload_report/`, param, config);

// 深农商行的校验保存
export const uploadr_save = (param, config) =>
  inst().post(`/upload/multiple-fs/save/`, param, config);

/**
 *  上传文件生成json
 *http://172.16.11.154:8000/report/json/generic/
 * {"company_code":"63e992cc-abf1-11e8-86fc-1c83410ad7e3","year":"2018","report_time":"00","report_type":"00"}
 */

export const get_word_report = (param, config) =>
  inst(false).post(`/report/json/generic/`, param, config);

/**
 *  小微企业生成报告json
 *http://172.16.11.154:8000/report/json/generic/
 *
 */

export const get_micro_report = (param, config) =>
  inst().post(`/3v3/get_micro_report/`, param, config);

/**
 *  上传文件生成json
 * http://192.168.43.150:8000/3v3/custom_rule/
 * 需要的参数：
 *   report:格式化后的报表，这个页面有很多地方在用的，应该是FormatSCInput的返回结果
  year：查询年份

    返回结果：与b22的页面一样，只是不要显示雷达图和雷达图的数据了。
 *
 */

export const custom_rule = (param) => inst().post(`/3v3/custom_rule/`, param);

/**
 *  上传文件生成json
 * http://192.168.43.150:8000/3v3/ExportReport/
 * 需要的参数：
 *
 year = serializer.validated_data.get("year")
report_time = serializer.validated_data.get("report_time")
report_type = serializer.validated_data.get("report_type")
company_code = serializer.validated_data.get("company_code")。
 *
 */

export const ExportReport = (param) => inst().post(`/3v3/ExportReport/`, param);

/**
 *  上传文件生成json
 * http://192.168.43.150:8000/pay/wxpay/
 * 需要的参数：
 *
 year = serializer.validated_data.get("year")
report_time = serializer.validated_data.get("report_time")
report_type = serializer.validated_data.get("report_type")
company_code = serializer.validated_data.get("company_code")。
 *
 */

export const wx_payresult = (order_num, pay = 1) =>
  inst().get(
    // `${
    //   pay ? "/pay/alipay/notify/" : "/pay/wxpay/"
    // }?order_num=${order_num}`
    `${pay ? "/pay/alipay/" : "/pay/wxpay/"
    }${order_num}/`
  );

export const getfileType = (param) => inst().get(`/v3/crbank/file_type_list/`);

// 用户邮箱绑定与修改
export const UpdateUserEmail = (param) =>
  inst().post(`/uc/userinfo/update-email/`, param);
// 发送邮件
export const sendUserEmail = (param) =>
  inst().post(`/v3/crbank/send_email/`, param);

/**
 * 报告企业经营现状分析资产质量分析权限项目分析
 *http://172.16.11.154:8000/3v3/report/json/fb/
 *
 */
export const index_change = (param, config) =>
  inst().post(`/report/json/fb-ex/`, param, config);

/**
 * 报告企业经营现状分析资产质量分析资产项目分析
 *http://172.16.11.154:8000/3v3/report/json/fb/
 *
 */

export const fb_simpleanalysis = (param, config) =>
  inst().post(`/report/json/fb/`, param, config);

/**
 * 报告现金流分析
 *http://172.16.45.157:9000/report/json/fc/
 *
 */
export const fc_simpleanalysis = (param, config) =>
  inst().post(`/report/json/fc/`, param, config);

/**
 * 删除我的企业
 *http://172.16.45.157:9000/company/remove/
 *
 */
export const removecompany = (company_code, config) =>
  inst().delete(`/company/remove/${company_code}/`, null, config);

/**
 * 事业单位财务报表总览
 * @param {*} param 
 */
export const causeWhitewash = (param) =>
  inst().post(`institution/whitewash/summary/`, param)

/**
 * 事业单位简版表格展示
 * @param {*} param 
 * @returns 
 */
export const causeSimple = (param) =>
  inst().post(`institution/financing-calc/simple/`, param)

/**
 * 事业单位财务风险分析
 * @param {*} param 
 * @returns 
 */
export const causeFiveAbilities = (param) =>
  inst().post(`institution/risk/five-abilities/`, param)

/**
 * 事业单位五大能力指标
 * @param {*} param 
 * @returns 
 */
export const causeIndexCalc = (param) =>
  inst().post(`institution/risk/index-calc/`, param)

/**
 * 事业单位五大能力走势图
 * @param {*} param 
 * @returns 
 */
export const industry_trends = (param) =>
  inst().post(`/risk/index-calc/industry-trends/`, param)


/**
 * 事业单位五大能力字段
 * @param {*} param 
 * @returns 
 */
export const causeIndexCate = (param) =>
  inst().get(`institution/risk/index-cate/`, param)

export const causeRport = (param) =>
  inst().post(`report/json/institution-tpl1/`, param)

// 步骤状态接口获取判断条件
export function driverget(param) {
  return inst().get(`${window.BASE_URL}/uc/is-help/`, param);
}

// 步骤状态接口
export function driverpost(param) {
  return inst().post(`${window.BASE_URL}/uc/is-help/`, param);
}

// ZZ-Score模型测算
export function zScore(param) {
  return inst().post(`${window.BASE_URL}/risk/zscore/`, param);
}

// 风险检测(财务画像用)
export function proFile_fin(param) {
  return inst().post(`${window.BASE_URL}/risk/profile_fin/`, param);
}

// 风险洞察汇总
export function proFile(param) {
  return inst().post(`${window.BASE_URL}/risk/profile/`, param);
}
// 风险洞察详情
export function profile_detail(param) {
  return inst().post(`${window.BASE_URL}/risk/profile_detail/`, param);
}

// 报表管理列表
export function dataList(size, page, param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_display/pagination/` + size + '/' + page + '/', param)
}

// 模板解析
export const parseupload = (param, config) =>
  inst().post(`${window.BASE_URL}/fs-mgt/template-parse/parse/`, param, config);

// 模板上传
export const saveupload = (param) =>
  inst().post(`${window.BASE_URL}/fs-mgt/templated-fs/save/`, param);

// 手工录入前检查是否重复
export const statement_exist = (param) =>
  inst().post(`${window.BASE_URL}/fs-mgt/fs_management/statement_exist/`, param);


// 手工录入上期报表
export const echo_annotation = (param) =>
  inst().post(`${window.BASE_URL}/fs-mgt/fs_management/echo_annotation/`, param);



// 修改前前检查是否可以修改
export const update_check = (param) =>
  inst().post(`${window.BASE_URL}/fs-mgt/fs_management/update_check/`, param);

// 修改前前检查是否可以修改
export const read_annotation_auth = (param) =>
  inst().post(`${window.BASE_URL}/fs-mgt/fs_management/read_annotation_auth/`, param);

// 辅助表手工录入前检查是否重复
export const annotation_exist = (param) =>
  inst().post(`${window.BASE_URL}/fs-mgt/fs_management/annotation_exist/`, param);


// 列表数据报表删除
export function fs_delete(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/fs_delete/`, param);
}

// 列表数据手工报表上传
export function hand_save(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/hand_save/`, param);
}

// 列表数据查看详情和修改获取数据
export function getData(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/read/`, param);
}
// 暂时存储区获取
export function hand_save_read(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/hand_save_read/`, param);
}

// 暂时存储区存储
export function hand_save_cache(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/hand_save_cache/`, param);
}

// 获取修改时间列表
export function change_log(param) {
  return inst().get(`${window.BASE_URL}/fs-mgt/change_log/fs/?company_code=${param.company_code}&report_year=${param.report_year}&report_time=${param.report_time}&report_type=${param.report_type}`);
}

// 获取上期报表
export function fs_management(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/echo/`, param);
}

// 获取修改记录列表
export function change_logFs(param) {
  return inst().get(`${window.BASE_URL}/fs-mgt/change_log/fs/${param.log_id}/?company_code=${param.company_code}&report_year=${param.report_year}&report_time=${param.report_time}&report_type=${param.report_type}`);
}

// 获取授信辅助表修改时间列表
export function change_logOt(param) {
  return inst().get(`${window.BASE_URL}/fs-mgt/change_log/annot/?company_code=${param.company_code}&report_year=${param.report_year}&report_time=${param.report_time}&report_type=${param.report_type}&annotation_type=${param.annotation_type}`);
}

// 获取授信辅助表修改记录列表
export function change_logEdit(param) {
  return inst().get(`${window.BASE_URL}/fs-mgt/change_log/annot/${param.log_id}/?company_code=${param.company_code}&report_year=${param.report_year}&report_time=${param.report_time}&report_type=${param.report_type}&annotation_type=${param.annotation_type}`);
}

// 辅助表列表获取
export function fs_dataList(size, page, param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_display/pagination_annotation/` + size + '/' + page + '/', param)
}

// 附注表列表详情查看
// http://172.16.10.18:11100/

export function read_annotation(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/read_annotation/`, param);
}


// 辅助表列表数据报表删除
export function annotation_delete(param) {
  return inst().post(`${window.BASE_URL}/fs-mgt/fs_management/annotation_delete/`, param);
}

// 粉饰补充数据图片PDF
export const my_upload = (param, config) =>
  inst().post(`${window.BASE_URL}/whitewash/rp-remark/upload/`, param, config);

// 粉饰补充数据图片PDF描述
export const my_uploadText = (param) =>
  inst().post(`${window.BASE_URL}/whitewash/rp-remark/save/`, param);

// 获取补充材料
export const my_getTextpg = (param) =>
  inst().post(`${window.BASE_URL}/whitewash/rp-remark/retrive/`, param);

// 删除补充材料
export const remove_attach = (param) =>
  inst().post(`${window.BASE_URL}/whitewash/rp-remark/remove_attach/`, param);

export function downloadIm(id) {
  return inst().get(`${window.BASE_URL}/whitewash/rp-remark/download/?fid=` + id);
}



// 修改S1  + 手工录入
export const S_save = (param) =>
  inst().post(`${window.BASE_URL}/fs-mgt/fs_management/annotation_save/`, param);

// 自定义规则
// 异常列表风险点
export function get_rule(param) {
  return inst().get(`${window.BASE_URL}/customize-rule/risk/?page_count=${param.size}&page_no=${param.page}&style=${param.type}`);
}

// 异常列表粉饰点
export function get_rule_wh(param) {
  return inst().get(`${window.BASE_URL}/customize-rule/wh/?page_count=${param.size}&page_no=${param.page}&style=${param.type}`);
}

// 禁用


//粉饰删除
export function deleteRule(id) {
  return inst().delete(`${window.BASE_URL}/customize-rule/wh/${id}/`);
}
//风险删除
export function fxdeleteRule(id) {
  return inst().delete(`${window.BASE_URL}/customize-rule/risk/${id}/`);
}

// 粉饰更新
export function putRule(id, params) {
  return inst().put(`${window.BASE_URL}/customize-rule/wh/${id}/`, params);
}
// 风险更新
export function fxputRule(id, params) {
  return inst().put(`${window.BASE_URL}/customize-rule/risk/${id}/`, params);
}

// 三表
export function subject(name) {
  return inst().get(`${window.BASE_URL}/customize-rule/subject?subject_type=${name}`);
}
// 指标
export function quota(name) {
  return inst().get(`${window.BASE_URL}/customize-rule/quota?genre=${name}`);
}

// 粉饰创建
export function addRule(params) {
  return inst().post(`${window.BASE_URL}/customize-rule/wh/`, params);
}

// 风险创建
export function fxaddRule(params) {
  return inst().post(`${window.BASE_URL}/customize-rule/risk/`, params);
}


// 获取自定义粉饰
export function rule_fails(params) {
  // console.log(params);
  return inst().get(`${window.BASE_URL}/customize-rule/fails?company_code=${params.company_code}&industry_code=${params.industry_code}&year=${params.year}&report_time=${params.report_time}&report_type=${params.report_type}&style=${params.style}`);
}



// OCR  图片上传识别
export const recognize = (param, config) =>
  inst().post(`${window.BASE_URL}/upload/ocr/recognize/`, param, config);

// 确定上传
export const intellect_rcg = (param) =>
  inst().post(`${window.BASE_URL}/upload/ocr-web/intellect-rcg/`, param);

// 合计项检查
export const checkParam = (param) =>
  inst().post(`${window.BASE_URL}/upload/ocr-web/check/`, param);
// 资表平衡检查
export const checkBalance = (param) =>
  inst().post(`${window.BASE_URL}/upload/ocr-web/balance/`, param);

// 保存
export const save = (param) =>
  inst().post(`${window.BASE_URL}/upload/ocr-web/save/`, param);

export const coverage = (param) =>
  inst().post(`${window.BASE_URL}/upload/ocr/coverage/`, param);


// 对标分析-当前公司
export const default_company = (param) =>
  inst().post(`${window.BASE_URL}/financing-calc/default_company/`, param);
// 对标分析-搜索
export const companylist = (param) =>
  inst().post(`${window.BASE_URL}/financing-calc/companylist/`, param);

// 对标分析-当前公司
export const financial_capacity = (param) =>
  inst().post(`${window.BASE_URL}/financing-calc/financial_capacity/`, param);
// 对标分析-搜索
export const risk_insight = (param) =>
  inst().post(`${window.BASE_URL}/financing-calc/risk_insight/`, param);
// 对标分析-当前公司
export const financial_capability = (param) =>
  inst().post(`${window.BASE_URL}/financing-calc/financial_capability/`, param);
// 对标分析-搜索
export const industry_ranking = (param) =>
  inst().post(`${window.BASE_URL}/financing-calc/industry_ranking/`, param);



// 投资总额
export const gross_assets = (param) =>
  inst().post(`${window.BASE_URL}/data/macro_data/gross_assets/`, param);

export const get_year = () =>
  inst().post(`${window.BASE_URL}/data/macro_data/gross_assets/get_year/`);

// 零售总额
export const social_consumer_goods = (param) =>
  inst().post(`${window.BASE_URL}/data/macro_data/social_consumer_goods/`, param);

// 分产业月度
export const gross_assets_industry = (param) =>
  inst().post(`${window.BASE_URL}/data/macro_data/month/gross_assets_industry/`, param);

export const get_year_month = () =>
  inst().post(`${window.BASE_URL}/data/macro_data/month/gross_assets_industry/get_year/`);

// 分产业年度
export const gross_assets_industry_year = (param) =>
  inst().post(`${window.BASE_URL}/data/macro_data/year/gross_assets_industry/`, param);



// 行业列表
export const gross_industry_level = (param) =>
  inst().post(`${window.BASE_URL}/data/macro_data/gross_industry_level/`, param);

// f分行业
export const gross_industry = (param) =>
  inst().post(`${window.BASE_URL}/data/macro_data/gross_industry/`, param);

// 社会融资规模存量
export function finace_stock(params) {
  return inst().get(`${window.BASE_URL}/data/finance/finace_stock/`);
}
// 社会融资规模增量
export function finace_increment(params) {
  return inst().get(`${window.BASE_URL}/data/finance/finace_increment/?year=${params}`);
}


// 地区社会融资规模增量
export function finace_region_increment(params) {
  return inst().get(`${window.BASE_URL}/data/finance/finace_region_increment/?region=${params}`);
}


// 数据地图年份
export function map_get_year(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/map_get_year/`);
}

// 数据地图
export function gdp_data_map(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/gdp_data_map/`, params);
}

//新闻
export function news(params) {
  return inst().get(`${window.BASE_URL}/data/macro_data/news/?page_size=${params.page_size}&page=${params.page}`);
}
// 国内生茶总之
export function gdp(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/gdp/`);
}
// 地区下拉
export function gdp_makeup_get_region(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/gdp_makeup_get_region/`);
}
// 地区数据
export function gdp_makeup_region(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/gdp_makeup_region/`, params);
}

// 行业下拉
export function gdp_makeup_get_industry(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/gdp_makeup_get_industry/`);
}
// 行业数据
export function gdp_makeup_industry(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/gdp_makeup_industry/`, params);
}

// 采购指数
export function pmi(params) {
  return inst().post(`${window.BASE_URL}/data/macro_data/pmi/`);
}

